module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.shroom4you.com/graphql","verbose":true,"schema":{"timeout":300000},"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":true},"production":{"hardCacheMediaFiles":false},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true}},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":500}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shroom Blog","short_name":"Shroom Blog","start_url":"/","background_color":"#ffffff","theme_color":"#f7adb6","display":"minimal-ui","icon":"content/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6908e7c5c98ed62f5951d9d9e2b87773"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
